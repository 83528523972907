import React, {useEffect } from "react";
import { graphql } from "gatsby";
import ArticlesComponent from "../components/articles";
import Layout from "../components/layout";
import Newsletter from "../components/newsletter";

export const query = graphql`
  query Categoria {
    articles: allStrapiArticle (sort: {order: DESC, fields: published_at}) {
      edges {
        node {
          slug
          title
          category {
            name
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 800, height: 500)
              }
            }
          }
          author {
            name
            picture {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 30)
                }
              }
            }
          }
        }
      }
    }

  }
`;

const Category = ({ data }) => {
  const articles = data.articles.edges;
  //const category = data.category.name;
  const seo = {
    metaTitle: "Recetas fáciles y saludables",
    metaDescription: "Las recetas de la cocina sana son recetas sencillas y prácticas para hacer todos los días, y para ganar salud",
  };

  return (
    <Layout seo={seo}>
        <div className="">
          {/* <h1>{category}</h1> */}
          <ArticlesComponent articles={articles} />
        </div>
        <Newsletter />
    </Layout>
  );
};

export default Category;

