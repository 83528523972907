import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const Articles = ({ articles }) => {
  // const leftArticlesCount = Math.ceil(articles.length / 5);
  // const leftArticles = articles.slice(0, leftArticlesCount);
  // const rightArticles = articles.slice(leftArticlesCount, articles.length);

    return (
      <div className="bg-white">
        <div className="max-w-2xl mx-auto py-6 px-4 sm:py-2 sm:px-6 lg:max-w-6xl lg:px-8">
        <h1 className="max-w-3xl m-auto poppins px-4  pt-0 md:text-s38 text-s33 leading-8 md:leading-11 pb-6 text-black text-center">Recetas Saludables</h1>
          <div className="grid grid-cols-1 gap-y-8 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:grid-cols-3 xl:gap-x-8">
            {articles.map((article) => (
              <div key={article.node.slug}>
              <Link to={`/recetas/${article.node.slug}`}>
                <div className="bg-gray-200 overflow-hidden">
                <GatsbyImage
                    image={
                      article.node.image.localFile.childImageSharp.gatsbyImageData
                    }
                    alt={article.node.title}
                    className="object-center object-cover group-hover:opacity-75 z-10 "
                  />
                </div>
                <h3 className="mt-2 text-s16 leading-5 text-center text-gray-700 poppins">{article.node.title}</h3>
              </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
};

export default Articles;
